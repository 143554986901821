<template>
    <div v-if="isDataSourceCorrect" class="p-relative">

        <Toolbar 
            :menu="dataSource.Data.Menu" 
            v-on:toolbar-button-click="onToolbarClick"  
        />

        <DocumentPanel
            :document-info="this.dataSource.Data.Object"
        />

        <v-tabs
            v-model="tab"
            background-color="transparent"
            slider-size="3"
            class="tab-selector-panel"
        >
            <v-tabs-slider color="#E43958"></v-tabs-slider>

            <v-tab 
                v-for="item in pages"
                :key="item.FormId"
                @click="onTabClicked(item.FormId)"
            >
                <template v-if="item.Count < 0">
                    {{ item.Name }}
                </template> 
                <template v-else>
                    {{ item.Name }}
                    <span class="count" id="attachCounter">{{ item.Count }}</span>
                </template>   
            </v-tab>

        </v-tabs>

        <v-tabs-items
            v-model="tab"
            class="tab-content-panel"
        >
            <v-tab-item 
                v-for="item in pages"
                :key="item.FormId"
            >
                <template v-if="item.FormId == document.id">
                    <v-form class="wrapperForm" ref="form" lazy-validation>
                        <v-row dense>
                            <!--#region Реквизиты документа-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card-panel title="Реквизиты_наряда">
                                    <v-field label="Текст_наряда">
                                        <template v-if="isViewMode" v-slot:view>{{ task.ResolutionText }}</template>
                                        <template v-else v-slot:edit>
                                            <div class="icon-click-row-group">
                                                <div @click="onSelectResolutionText">
                                                    <v-icon small v-tooltip.left-center="selectResolutionTextTooltip">
                                                        fas fa-edit
                                                    </v-icon>
                                                </div>
                                                <v-textarea
                                                    :value="task.ResolutionText"
                                                    @input="update({ property: 'Data.Object.Tasks[0].ResolutionText', value: $event })"
                                                    rows="3"
                                                    no-resize
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                >
                                                </v-textarea>
                                            </div>

                                        </template>
                                    </v-field>
                                    <!-- <v-field label="Тип_контроля">
                                        <template v-if="isViewMode" v-slot:view>{{ task.ControlType.Value }}</template>
                                        <template v-else v-slot:edit>
                                                <v-autocomplete
                                                    :value="task.ControlType"
                                                    @input="update({ property: 'Data.Object.Tasks[0].ControlType', value: $event })"
                                                    :items="allowedControlTypes" 
                                                    :item-text="item => $refLocale(item, $i18n.locale)" 
                                                    item-value="id"
                                                    return-object
                                                    hide-details
                                                    required
                                                    outlined
                                                    dense
                                                    :rules="requiredRule"
                                                    append-icon="fas fa-chevron-down"
                                                >
                                                </v-autocomplete>
                                        </template>
                                    </v-field>
                                    <v-field v-if="isControlTypeControl" label="Личный_контроль">
                                        <template v-if="isViewMode" v-slot:view>{{ (task.IsSignerControl ? $t("Да") : $t("Нет")) }}</template>
                                        <template v-else v-slot:edit>
                                            <v-switch
                                                :input-value="task.IsSignerControl"
                                                @change="update({ property: 'Data.Object.Tasks[0].IsSignerControl', value: $event })"
                                                class="cust-switch"
                                                inset
                                                hide-details
                                            >
                                            </v-switch>
                                        </template>
                                    </v-field> -->
                                    <v-field v-if="isControlTypeControl" label="Контрольный_срок">
                                        <template v-if="isViewMode" v-slot:view>{{ (task.is_new_record ? formatDate(task.MaxControlDate) : formatDate(task.InnerLimit)) }}</template>
                                        <template v-else v-slot:edit>
                                            <v-menu 
                                                v-model="controleDateMenu"
                                                :close-on-content-click="false"
                                                :nudge-right="40"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="290"
                                            >
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-text-field
                                                        :value="innerLimit | formattedDate"
                                                        prepend-icon="mdi-calendar"
                                                        readonly
                                                        v-bind="attrs"
                                                        v-on="on" 
                                                        hide-details
                                                        required
                                                        outlined
                                                        dense
                                                        class="datepick-input"
                                                    >
                                                    </v-text-field>
                                                </template>

                                                <v-date-picker
                                                    v-model="innerLimit"
                                                    @input="controleDateMenu = false"
                                                    color="teal"
                                                    :max="controlDateRange.Max"
                                                    :min="controlDateRange.Min"
                                                    :first-day-of-week="1"
                                                >
                                                </v-date-picker>
                                            </v-menu>

                                        </template>
                                    </v-field>
                                    <!-- <v-field label="Сроки_продлений">
                                        <template v-slot:view>
                                            {{ (!task.ProlongationDateList ? $t("Не_указано") : task.ProlongationDateList.replace(" 0:00:00", "")) }}
                                        </template>
                                    </v-field> -->
                                    <v-field label="Статус">
                                        <template v-slot:view>
                                            <StatusChip 
                                                :status="availableStatuses[$helpers.getTaskStatus(task.WorkStatus)]"
                                            />                                        
                                        </template>
                                    </v-field>
                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->

                            <!--#region Краткое содержание документа-->
                            <v-col cols="12" sm="12" md="6" class="section-wrapper">

                                <v-card-panel title="Краткое_содержание_заявки">
                                    <v-field label="Заявка">
                                        <template v-slot:view>
                                            <v-document-link
                                                :isViewMode="isViewMode"
                                                :id="dataSource.Data.Object.DocumentID" 
                                                :type="'Chancellery|Documents.Document'"
                                                :text="dataSource.Data.Object.DocumentNumber"
                                            />
                                        </template>
                                    </v-field>
                                    <v-field label="Краткое_содержание_заявки">
                                        <template v-slot:view>
                                            {{ (isEuolResolution ?  document.Card.Annotation : document.Theme)}}
                                        </template>
                                    </v-field>                                    
                                </v-card-panel>

                                <v-card-panel title="Кем_создано" v-if="isViewMode">
                                    <v-field label="Автор">
                                        <template v-slot:view>
                                            <v-employee-chip
                                                :id="dataSource.Data.Object.SignerID"
                                                :name="dataSource.Data.Object.SignerName"
                                            >
                                            </v-employee-chip>
                                        </template>
                                    </v-field>
                                    <v-field label="Дата_создания">
                                        <template v-slot:view>
                                            {{ dataSource.Data.Object.ResolutionDate ? formatDate(dataSource.Data.Object.ResolutionDate) : $t('Не_указано') }}
                                        </template>
                                    </v-field>                                    
                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->

                            <!--#region Исполнители-->
                            <v-col cols="12" sm="12" md="12" class="section-wrapper">

                                <v-card-panel title="Исполнители">
                                    
                                    <template v-slot:titleappend>
                                        <span
                                            class="fbt-after-edit"
                                            v-if="!isViewMode"
                                            @click="onExecuterSelect"
                                        >
                                            <v-icon 
                                                small 
                                                v-tooltip.left-center="selectExecuterTooltip"
                                            >
                                                fas fa-edit
                                            </v-icon>
                                        </span>
                                    </template>

                                    <template v-if="isViewMode">

                                        <v-field
                                            v-if="!!svodExecuters && svodExecuters.length"
                                            label="Ответственный"
                                            :wide="true"
                                        >

                                            <template v-slot:view>
                                                <template v-for="executer in svodExecuters">
                                                    <v-enterprise-chip
                                                        v-if="executer.ExecuterID == guidEmpty"
                                                        :key="executer.id"
                                                        :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                        :main="true"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :key="executer.id"
                                                        :id="executer.ExecuterID"
                                                        :name="executer.ExecuterName"
                                                        :main="true"
                                                    />
                                                </template>
                                            </template>

                                        </v-field>

                                        <v-field
                                            v-if="executers.length > 0"
                                            :label="(isControlTypeControl ? 'Соисполнители' : 'Исполнители' )"
                                            :wide="true"
                                            :multi="true"
                                        >

                                            <template v-slot:view>
                                                <template v-for="executer in executers">
                                                    <v-enterprise-chip
                                                        v-if="executer.ExecuterID == guidEmpty"
                                                        :key="executer.id"
                                                        :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                    />
                                                    <v-workplace-chip
                                                        v-else
                                                        :key="executer.id"
                                                        :id="executer.ExecuterID"
                                                        :name="executer.ExecuterName"
                                                    />
                                                </template>
                                            </template>

                                        </v-field>

                                    </template>
                                    <template v-else>
                                        
                                        <v-field 
                                            v-if="isControlTypeControl"
                                            label="Ответственный"
                                            :wide="true"
                                        >

                                            <template v-slot:edit>
                                                <div class="chipAreaNoData" v-if="svodExecuters.length == 0 && !dragStart"></div>
                                                <draggable
                                                    v-model="svodExecuters"
                                                    v-bind="draggableOptions"
                                                    :class="[dragStart ? 'isDragClass onlyReadData' : 'onlyReadData']"
                                                    v-else
                                                >
                                                    <template v-for="executer in svodExecuters">
                                                        <v-enterprise-chip
                                                            v-if="executer.ExecuterID == guidEmpty"
                                                            :key="executer.id"
                                                            :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                            :main="true"
                                                        />
                                                        <v-workplace-chip
                                                            v-else
                                                            :key="executer.id"
                                                            :id="executer.ExecuterID"
                                                            :name="executer.ExecuterName"
                                                            :main="true"
                                                        />
                                                    </template>
                                                </draggable>
                                            </template>

                                        </v-field>  

                                        <!-- <v-field
                                            :label="(isControlTypeControl ? 'Соисполнители' : 'Исполнители' )"
                                            :wide="true"
                                        >
                                            <template v-slot:edit>
                                                <draggable
                                                    v-model="executers"
                                                    v-bind="draggableOptions"
                                                    @start="onDragStart"
                                                    @end="onDragEnd"
                                                    :class="['more-per-lab-wrap', 'onlyReadData']"
                                                >
                                                    <template v-for="executer in executers">
                                                        <v-enterprise-chip
                                                            v-if="executer.ExecuterID == guidEmpty"
                                                            :key="executer.id"
                                                            :name="(executer.EnterpriseName ? executer.EnterpriseName : executer.ExecuterName)"
                                                        />
                                                        <v-workplace-chip
                                                            v-else
                                                            :key="executer.id"
                                                            :id="executer.ExecuterID"
                                                            :name="executer.ExecuterName"
                                                        />
                                                    </template>

                                                </draggable>                                            
                                            </template>
                                        </v-field> -->
                                    </template>

                                </v-card-panel>

                            </v-col>
                            <!--#endregion-->
                            
                            <!--#region Ход исполнения-->
                            <v-col v-if="isViewMode" cols="12" sm="12" md="12" class="section-wrapper">
                                <v-card-panel title="Ход_исполнения">
                                    
                                    <template v-slot:titleactions>              
                                        <div class="fbt-slider">
                                            <!--
                                            <div class="prev-title">{{$t('Показать_все_исполнения')}}</div>
                                            <v-switch 
                                                v-model="allExecutions" 
                                                class="cust-switch" 
                                                inset 
                                                hide-details                                               
                                            >
                                            </v-switch>
                                            -->
                                            <div
                                                class="arrow-dropdown"
                                                @click="toggleExpanded"
                                            >
                                                <v-icon 
                                                role="button" 
                                                small 
                                                left>
                                                    {{ expanded ? "fas fa-chevron-up" : "fas fa-chevron-down" }}
                                                </v-icon>
                                            </div>
                                        </div>
                                    </template>
                    
                                    <v-execution-tree
                                        :task-click="taskClickHandler"
                                        :task-dbl-click="taskDblClickHandler"
                                        :allExecutions="allExecutions"
                                        :expanded.sync="expanded"
                                    >
                                    </v-execution-tree>
                                </v-card-panel>
                            </v-col>
                            <!--#endregion-->
                            
                        </v-row>
                    </v-form>
                </template>
                
                <template v-else>
                    <component                         
                        :is="$mapComponent(item.FormId)"
                        :ref="item.FormId"
                        :id="document.id"
                    />
                </template>

            </v-tab-item>

        </v-tabs-items>

        <SelectResolutionTextDlg v-if="!isViewMode" ref="SelectResolutionTextDlgRef" />

    </div>
</template>

<script>
import Toolbar from '@/components/Toolbar.vue';
import DocumentPanel from '@/components/DocumentPanel.vue';
import SelectResolutionTextDlg from '@/components/dialogs/SelectResolutionTextDlg';
import { mapActions, mapGetters } from 'vuex';
import StatusChip from '@/components/StatusChip';
import sys from '@/services/system';
import draggable from 'vuedraggable';
import i18n from '@/i18n';
import _ from 'lodash';

export default {
    name: "IQalaOrderCard",
    components: {
        Toolbar,
        DocumentPanel,
        SelectResolutionTextDlg,
        StatusChip,
        draggable
    },     
    data() {
        return {
            tab: null,
            openAll: true,
            allExecutions: false,
            expanded: false,
            controleDateMenu: false,

            draggableOptions: {
                animation: 300,
                group: "group",
                disabled: false,
            },

            prevExecuterId: null,
            dragStart: false,
        }
    },
    filters: {
        formattedDate: function (value) {
            var formatedDate = sys.dateFormat(value, 'DD.MM.YYYY');
            return formatedDate ?? "";
        }
    },
    computed: {
        ...mapGetters('references', ['GetReference', 'GetIQalaDeclarantCategoryTypes']),
        ...mapGetters('actionsource', { dataSource: 'getDataSource'}), 
        ...mapGetters({ 
            requiredRule: 'getRequiredRule'
        }),
        allowedControlTypes() {
            return this.GetReference(1004).filter(i => 
                this.dataSource?.Data?.AdditionalObjects 
                && this.dataSource?.Data?.AdditionalObjects.length 
                && this.dataSource?.Data?.AdditionalObjects[0]
                && this.dataSource?.Data?.AdditionalObjects[0].length
                    ? this.dataSource.Data.AdditionalObjects[0].includes(i.id)
                    : true
            );
        },
        isDataSourceCorrect() {                
            return this.dataSource?.Data?.Object?.__type === "IQalaOrder:#Avrora.Objects.Modules.Docflow.DocflowObjects";
        },
        controlDateRange() {
            let max = this.$moment(this.task.MaxControlDate)
            let min = this.$moment(this.dataSource?.Data?.Object?.ResolutionDate);

            let Max = "";
            let Min = "";

            if (max && max.isValid()) {
                Max = max.format('YYYY-MM-DD');

                if (min && min.isValid() && min.isBefore(max))                           
                    Min = min.format('YYYY-MM-DD');       
            }
            else if (min && min.isValid()) {
                Min = min.format('YYYY-MM-DD');
            }

            return { Max, Min };
        },
        innerLimit: {
            get: function() {
                if (this.task.InnerLimit)
                    return this.$moment(this.task.InnerLimit).format('YYYY-MM-DD');
                
                return this.task.InnerLimit;
            },
            set: function(v) {
                this.updateSource({ property: `Data.Object.Tasks[0].InnerLimit`, value: `/Date(${Date.parse(v)})/` });
            }
        },
        guidEmpty() {
            return '00000000-0000-0000-0000-000000000000';
        },
        availableStatuses() {
            return this.$store.getters['references/GetAvaibleStatuses'];
        },
        isViewMode() {
            return this.dataSource?.Data?.FormId == "1201021";
        },
        resolution() {
            return this.dataSource?.Data?.Object;
        },
        task() {
            return this.dataSource?.Data?.Object?.Tasks?.[0]
        },
        document() {
            return this.dataSource?.Data?.Object?.Document;
        },
        pages() {
            let pages = 
            [
                { Count: -1, FormId: this.document?.id, Name: this.$t("Карточка_наряда") }
            ];

            return pages;
        },
        isControlTypeControl() {
            return ![3,4].includes(this.task?.ControlType?.id);
        },
        isEuolResolution() {
            return document.__type === "EuolStatement:#Avrora.Objects.Modules.Docflow.DocflowObjects";  
        },
        isORDResolution() {
            return document.__type === "InnerDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects" && document.Card.TypeC4 == 1;  
        },
        isActItemResolution() {
            return document.__type === "IncomingDocument:#Avrora.Objects.Modules.Docflow.DocflowObjects" && document.Card.TypeC4 == 3;  
        },
        svodExecuters: {
            get: function () {
                return this.dataSource?.Data?.Object?.Tasks?.[0].Executers?.filter(e => e.Svod);
            },
            set: function(value) {
                if (value.length) {
                    let index = this.dataSource?.Data?.Object?.Tasks?.[0].Executers.indexOf(value[0]);
                    this.updateSource({ property: `Data.Object.Tasks[0].Executers[${index}].Svod`, value: true });
                }
                else {
                    this.dataSource.Data.Object.Tasks[0].Executers.forEach((executer, index) => {
                        if (executer.Svod)                                
                            this.updateSource({ property: `Data.Object.Tasks[0].Executers[${index}].Svod`, value: false });                            
                    });
                    this.updateSource({ property: `Data.Object.Tasks[0].Executers[0].Svod`, value: true }); 
                }                    
            }
        },
        executers: {
            get: function () { 
                return this.dataSource?.Data?.Object?.Tasks?.[0].Executers.filter(e => !e.Svod);
            },
            set: async function (value) {
                if (value.length > 0 && value.length == this.dataSource?.Data?.Object?.Tasks?.[0].Executers.length) {
                    value.forEach(i => { i.Svod = false });

                    this.updateSource({ property: `Data.Object.Tasks[0].Executers`, value });
                }
            }
        },            
        selectResolutionTextTooltip() {
            return this.$t("Выбрать_текст_наряда");
        },
        selectExecuterTooltip() {
            return this.$t("Выбрать_исполнителей");
        },
        declarantCategoryName() {
            return this.GetIQalaDeclarantCategoryTypes.find(category => category.id === this.document.Card.DeclarantType).Value;
        },
    },
    methods: {
        ...mapActions('references', ['getReference']),
        ...mapActions('actionsource', { updateSource: 'updateDataSource' }),   
        toggleExpanded() {
            this.expanded = !this.expanded;                          
        },
        onTabClicked (formId) {
            if (this.$refs[formId])
                this.$refs[formId][0]?.update();
        },
        async onToolbarClick (event, button) {
            let actions = Reflect.ownKeys(this.$store._actions).filter(i => i.includes('actionsource/')).map(i => { return i.split('/')[1] })
            if (actions.includes(button.Action)) {
                 if(button.Action == 'Save' || button.Action == 'SaveAndClose'){
                    let validate_result = this.$refs.form[0].validate();
                    if(validate_result && this.svodExecuters.length > 0){
                        await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});        
                    }else{
                        this.$notify.alert(i18n.t("Не_заполнены_обязательные_поля"));
                    }
                }
                else
                {
                    await this.$store.dispatch(`actionsource/${button.Action}`, {event, button});
                }
            }
            else
                this.$notify.alert(`Action ${button.Action} not emplemented`);
        },
        formatDate (source) {
            return this.$moment(source).format('DD.MM.YYYY');
        },
        taskClickHandler() {

        },
        taskDblClickHandler(item) {            
            if (item.text === '2')
                this.$store.dispatch(`actionsource/ExecuterShowExecution`, { executionData : { resolutionId: item.viewInfo.resolutionId, executionId: item.id, common: false } });
        },
        async onSelectResolutionText() {
            try
            {
                let resolutionText = await this.$refs.SelectResolutionTextDlgRef.open();
                this.updateSource({ property: 'Data.Object.Tasks[0].ResolutionText', value: resolutionText });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        async onExecuterSelect() {
            try
            {
                let selectMemberParams =  {
                    title: this.$t("Выбор_исполнителей"),
                    includeInner: true,
                    includeExecutersGroups: true,
                    multiple: false,
                    selected: Array.from(this.task.Executers)
                        .sort((x, y) => (x.IndexInTask > y.IndexInTask) ? 1 : -1)
                        .map(x => x.ExecuterID == '00000000-0000-0000-0000-000000000000' ? x.EnterpriseID : x.ExecuterID),
                    implicitExclude: [ this.dataSource.Data.Object.SignerWorkPlaceId ]
                }

                let result = await this.$store.dispatch('dialogs/selectMembersNew/open', selectMemberParams);
                
                let executersDataList =
                [
                    [ 
                        result.employeeId ? result.workplaceId : result.enterprise,
                        result.name,
                        result.employeeId ? "сотрудники" : "организации",
                        result.workplaceId
                    ]
                ];

                let resolutionTaskExecuters = [ sys.prepareResolutionTaskExecuter(this.task.id, 0, result) ];
                resolutionTaskExecuters[0].Svod = true;

                this.updateSource({ property: 'Data.Object.Tasks[0].Executers', value: resolutionTaskExecuters });
                this.updateSource({ property: 'Data.Object.Tasks[0].WebExecuterDataList.Data', value: executersDataList });
            }
            catch (ex)
            {
                console.log(ex.message);
            }
        },
        onDragStart() {
            this.dataSource.Data.Object.Tasks[0].Executers.forEach((executer, index) => {

                if (executer.Svod) {
                    this.prevExecuterId = executer.id;
                    this.updateSource({ property: `Data.Object.Tasks[0].Executers[${index}].Svod`, value: false });                     
                }
            });

            this.dragStart = true;
        },
        onDragEnd() {
            if (!this.dataSource?.Data?.Object?.Tasks?.[0].Executers.find(i => i.Svod) && this.prevExecuterId) {            
                let prevExecuter = this.dataSource.Data.Object.Tasks[0].Executers.find(i => i.id == this.prevExecuterId)

                if (prevExecuter) {
                    let index = this.dataSource.Data.Object.Tasks[0].Executers.indexOf(prevExecuter);
                    if (index >= 0)
                        this.updateSource({ property: `Data.Object.Tasks[0].Executers[${index}].Svod`, value: true });
                }
            }

            this.prevExecuterId = null;
            this.dragStart = false;
        },
        update: _.debounce(function (object) {
            this.updateSource(object);
        }, 250)
    },
    watch: {
        isControlTypeControl(val) {
            if (this.dataSource?.Data?.Object?.Tasks?.[0].Executers && this.dataSource?.Data?.Object?.Tasks?.[0].Executers.length) {
                if (val) {                    
                    this.updateSource({ property: 'Data.Object.Tasks[0].Executers[0].Svod', value: true });
                }
                else {
                    for(var index = 0; index < this.dataSource.Data.Object.Tasks[0].Executers.length; index++)
                        this.updateSource({ property: `Data.Object.Tasks[0].Executers[${index}].Svod`, value: false });
                }
            }
        }
    },
    async created() {        
        (async() => {
            while(typeof this.$refs.form === 'undefined')
                await new Promise(resolve => setTimeout(resolve, 100));

            this.$refs?.form?.[0]?.validate();
        })();
    },
    updated(){
        if(this.dataSource !== null){
            this.$refs?.form?.[0]?.validate();
        }  
    }
}
</script>