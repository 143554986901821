<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.options.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text
                    v-if="loading"
                >
                    <v-col
                        class="text-subtitle-1 text-center"
                        cols="12"
                    >
                        {{ $t("Загрузка...") }}
                    </v-col>

                    <v-col cols="12">
                        <v-progress-linear
                            color="#a94442"
                            indeterminate
                            rounded
                            height="6"
                        ></v-progress-linear>
                    </v-col>

                </v-card-text>
                <v-card-text
                    v-else
                    class="wrapperFormModal"
                >
                    <v-card flat>
                        <v-card-text>
                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="2">
                                    <label class="f-label">{{ $t("Поиск") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="10">
                                    <div class="text-field-with-icon">
                                        <v-text-field
                                            :value="search"
                                            @input="updateSearch($event)"
                                            hide-details
                                            required
                                            outlined
                                            dense
                                            append-icon="fas fa-search"
                                            clearable
                                            @click:clear="search = null"
                                        ></v-text-field>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card flat>
                        <v-card-text>

                            <v-list 
                                class="modal-select-list"
                                dense
                                outlined
                            >
                                <v-list-item-group
                                    v-if="resolutionTextsFiltered.length > 0"
                                    v-model="selectedResolutionTexts"
                                    color="cyan darken-1"
                                    multiple
                                >
                                    <v-list-item
                                        v-for="(item, i) in resolutionTextsFiltered"
                                        :key="i"
                                        :value="item"
                                        @dblclick="doubleSelect(item.Value)"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-html="highlight(item.Value)">
                                            </v-list-item-title>
                                        </v-list-item-content>

                                    </v-list-item>

                                </v-list-item-group>
                                <div v-else>
                                    <v-col cols="12" sm="12" md="12">
                                        <div class="onlyReadData">
                                            {{ $t("К_сожалению,_по_Вашему_запросу_ничего_не_найдено") }}
                                        </div>
                                    </v-col>
                                </div>

                            </v-list>

                        </v-card-text>
                    </v-card>
              
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>

                    <v-btn
                        color="cyan"
                        text
                        depressed
                        @click="save"
                        v-if="selectedResolutionTexts.length > 0"
                    >
                        {{ $t("Выбрать") }}
                    </v-btn>

                    <v-btn
                        color="blue-grey"
                        text
                        depressed
                        @click="cancel"
                    >
                        {{$t("Отмена")}}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import sys from '@/services/system';
import _ from 'lodash';
import { mapActions } from 'vuex';

export default {
    name: "SelectResolutionTextDlg",
    data () {
        return {
            visible: false,
            loading: false,
            resolve: null,
            reject: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },
            search: null,
            resolutionTexts: null,
            selectedResolutionTexts: []
        }
    },
    computed: {
        resolutionTextsFiltered() {
            if (this.search)
                return this.resolutionTexts?.filter(x => sys.checkSearch(x.Value, this.search) || this.selectedResolutionTexts.includes(x) ) ?? [];

            return this.resolutionTexts ?? [];
        }
    },
    methods: {
        ...mapActions('references', ['getReference']),
        async open(options = {})
        {
            this.options = Object.assign(this.options, options);
            this.loading = true;
            this.visible = true;
            this.search = null,
            this.selectedResolutionTexts = [];

            this.resolutionTexts = Array.from(await this.getReference({ id: 1017 }))
                .sort(function (a, b) {
                    return ('' + a.Value).localeCompare(b.Value);
                });

            this.loading = false;

            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        cancel() {
            this.visible = false;
            this.reject({
                isCancelled: true,
                message: "Действие_отменено"
            });
        },
        save() {
            this.visible = false;
            this.resolve(this.selectedResolutionTexts.map(x => x.Value).join('\n'));
        },
        doubleSelect(item) {
            this.visible = false;
            this.resolve(item);
        },
        updateSearch: _.debounce(function (object) {
            this.search = object;
        }, 250),
        highlight(content) {
            if (!this.search)
                return content;

            let filter = this.search.trim();

            if (!filter)
                return content;

            let searchTokens = filter.split(' ');
            let fullsearchsuccess = false;
            let rez = String(content).replace(new RegExp(filter, "gi"), match => {
                fullsearchsuccess = true;
                return `<span style="background:#C5E1A5">${match}</span>`;
            });

            if (!fullsearchsuccess && searchTokens.length > 1) {
                searchTokens.forEach(element => {
                    if (element) {
                        rez = String(rez).replace(new RegExp(element, "gi"), match => {
                            return `<span style="background:#C5E1A5">${match}</span>`;
                        });
                    }
                });
            }

            return rez;          
        },
    }
}
</script>